












































import SortableTh from '@/components/SortableTh.vue';
import SortableTd from '@/components/SortableTd.vue';
import {
  SortableTableDataConfig,
  SortableTableHeader,
  SortableTdEvents
} from '@/types/SortableTableFormat';
import { TableSortAndFilter } from '@/types/TableSortAndFilter';
import Vue from 'vue';
export default Vue.extend({
  'name': 'SortableTable',
  'components': {
    SortableTh,
    SortableTd
  },
  'props': {
    'headers': {
      'required': true
    },
    'dataConfigs': {
      'required': true,
      'type': Array
    },
    'value': {
      'required': true
    }
  },
  data () {
    return {
      'events': [] as unknown
    };
  },
  'watch': {
    dataConfigs (configs) {
      this.$nextTick(() => {
        this.updateEvents(configs);
      });
    }
  },
  mounted () {
    this.updateEvents(this.dataConfigs as Array<SortableTableDataConfig>);
  },
  'methods': {
    updateEvents (configs: Array<SortableTableDataConfig>) {
      this.events = configs.map((config: SortableTableDataConfig) => {
        const colEvents = {} as SortableTdEvents;

        if (config.event) {
          colEvents[config.event] = (row: number) => {
            this.$emit(config.event as string, row);
          };
        }

        if (config.buttons) {
          config.buttons.forEach((button) => {
            colEvents[button.event] = (row: number) => {
              this.$emit(button.event, row);
            };
          });
        }

        return colEvents;
      });
    },
    updateSorting (itemIndex: number) {
      const header = (this.headers as Array<SortableTableHeader>)[itemIndex];
      switch (header.sortOrder) {
        case 0:
          header.sortOrder = 1;
          this.$emit('sort', {
            'sortByKey': header.key,
            'sortByOrder': 'asc'
          } as TableSortAndFilter);
          break;
        case 1:
          header.sortOrder = -1;
          this.$emit('sort', {
            'sortByKey': header.key,
            'sortByOrder': 'desc'
          } as TableSortAndFilter);
          break;
        case -1:
          header.sortOrder = 0;
          this.$emit('sort', {
            'sortByKey': header.key,
            'sortByOrder': ''
          } as TableSortAndFilter);
          break;
      }

      (this.headers as Array<SortableTableHeader>).forEach((header, index) => {
        if (index !== itemIndex) {
          header.sortOrder = 0;
        }
      });
    }
  }
});
