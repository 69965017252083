import { Pagination } from '../Pagination';
import { TableSortAndFilter } from '../TableSortAndFilter';

export interface FilterRequest {
  page: number,
  pageSize: number,
  search?: string,
  'sortBy.Key'?: string,
  'sortBy.Order'?: string
}

export function cleanupFilterRequest (form: FilterRequest): FilterRequest {
  if (form['sortBy.Key'] === '' || form['sortBy.Order'] === '') {
    delete form['sortBy.Key'];
    delete form['sortBy.Order'];
  }

  if (form.search === '') {
    delete form.search;
  }
  return form;
}

export function createFilterRequest (pagination: Pagination, tableSortAndFilter: TableSortAndFilter): FilterRequest {
  return {
    'page': pagination.page,
    'pageSize': pagination.pageSize,
    'search': tableSortAndFilter.search,
    'sortBy.Key': tableSortAndFilter.sortByKey,
    'sortBy.Order': tableSortAndFilter.sortByOrder
  };
}
