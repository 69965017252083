










import { SortableTableHeader } from '@/types/SortableTableFormat';

import Vue from 'vue';
export default Vue.extend({
  'name': 'SortableTh',
  'props': {
    'data': {
      'type': Object as () => SortableTableHeader,
      'required': true
    }
  },
  'methods': {
    toggleSort () {
      if (this.data.isSortable) {
        this.$emit('sort');
      }
    }
  }
});
