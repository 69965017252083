























import { SortableTableDataConfig } from '@/types/SortableTableFormat';
import Vue from 'vue';
export default Vue.extend({
  'name': 'SortableTd',
  'props': {
    'dataKey': {
      'type': String
    },
    'config': {
      'type': Object as () => SortableTableDataConfig,
      'required': true
    },
    'value': {
      'required': true
    },
    'row': {
      'required': true,
      'type': Number
    }
  },
  'computed': {
    display () {
      // @ts-expect-error: Ignore error explicitly.
      if (this.value[this.dataKey] != null || typeof this.value[this.dataKey] !== 'undefined') {
      // @ts-expect-error: Ignore error explicitly.
        if (this.config.isDate) {
          const dateTimeFormat = this.config.dateTimeFormat ? this.config.dateTimeFormat : 'YYYY-MM-DD';

          // @ts-expect-error: Ignore error explicitly.
          const dateInUTC = window.moment.utc(this.value[this.dataKey]).toDate();
          return window.moment(dateInUTC).local().format(dateTimeFormat);
        }

        // @ts-expect-error: Ignore error explicitly.
        if (this.config.valueFn) {
          // @ts-expect-error: Ignore error explicitly.
          return this.config.valueFn(this.value[this.dataKey]);
        }

        // @ts-expect-error: Ignore error explicitly.
        return this.value[this.dataKey];
      }
      return '';
    },
    customClasses () {
      // @ts-expect-error: Ignore error explicitly.
      if (this.config.classFn) {
        // @ts-expect-error: Ignore error explicitly.
        return this.config.classFn(this.value[this.dataKey]);
      }
      return '';
    }
  }
});
