export interface Pagination{
    page: number,
    pageSize: number,
    totalItems: number,
    totalPages: number
}

export function initPagination ():Pagination {
  return {
    'page': 1,
    'pageSize': 20,
    'totalItems': 0,
    'totalPages': 0
  };
}

export function updatePagination (pagination: Pagination, paginationUpdate: Pagination): void{
  pagination.page = paginationUpdate.page;
  pagination.pageSize = paginationUpdate.pageSize;
  pagination.totalItems = paginationUpdate.totalItems;
  pagination.totalPages = paginationUpdate.totalPages;
}
