export interface TableSortAndFilter {
    search?: string,
    sortByKey: string,
    sortByOrder: ''| 'desc'| 'asc'
}

export function initTableSortAndFilter ():TableSortAndFilter {
  return {
    'search': '',
    'sortByKey': '',
    'sortByOrder': ''
  };
}

export function updateTableSort (tableSortAndFilter: TableSortAndFilter, updateTableSortAndFilter:TableSortAndFilter):void{
  tableSortAndFilter.sortByKey = updateTableSortAndFilter.sortByKey;
  tableSortAndFilter.sortByOrder = updateTableSortAndFilter.sortByOrder;
}
